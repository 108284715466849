/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eqeqeq */
import React from "react";
import { container } from "tsyringe";
import { PelatihanPresenter } from "../../../../../data/presenters/PelatihanPresenter";
import { PathMateriPresenter } from "../../../../../data/presenters/PathMateriPresenter";
import { LogLatihanPresenter } from "../../../../../data/presenters/LogLatihanPresenter";
import { Pelatihan } from "../../../../../entities/Pelatihan";
import { LogLatihan } from "../../../../../entities/LogLatihan";
import { useIsAuthenticated } from "react-auth-kit";

interface IState {
  isLoading: boolean;
  isLoadingNext: boolean;
  isLoadingLog: boolean;
  pelatihan: Pelatihan | null;
  indexData: number;
  indexMateri: number;
  pathMateri: any[];
  judulMateri: string;
  uraianDeskripsi: string;
  views: number;
  tanggalPembuatan: string;
  ada_latihan: number;
  judulSubModul: string;
  jenisSoal: string;
  jumlahSoal: number;
  latihan_id: number;
  status_latihan: string;
  logLatihanData: LogLatihan[];
  indexPathMateri: number;
  statusKonsultasi: number;
  initialTimeVideo: number;
  progressTime: number;
  statusSubModul: string;
  pageRowRiwayatLatihan: number;
  showModalLatihan: boolean;
  showModalUjian: boolean;
}

interface InitialState {
  state: IState;
  getDetailPelatihan: Function;
  setMateri: Function;
  setIndexData: Function;
  setIndexMateri: Function;
  handleNextSubModul: Function;
  getLogLatihan: Function;
  setStatusLatihan: Function;
  setIndexPathMateri: Function;
  setInitialTimeVideo: Function;
  setProgressTime: Function;
  setPageRowRiwayatLatihan: Function;
  setShowModalLatihan: Function;
  setShowModalUjian: Function;
}

const initialState = {
  state: {
    isLoading: true,
    isLoadingNext: false,
    isLoadingLog: true,
    pelatihan: null,
    indexData: 0,
    indexMateri: 0,
    pathMateri: [],
    judulMateri: "",
    uraianDeskripsi: "",
    views: 0,
    tanggalPembuatan: "",
    ada_latihan: 0,
    judulSubModul: "",
    jenisSoal: "",
    jumlahSoal: 0,
    latihan_id: 0,
    status_latihan: "",
    logLatihanData: [],
    indexPathMateri: 0,
    statusKonsultasi: 0,
    initialTimeVideo: 0,
    progressTime: 0,
    statusSubModul: "",
    pageRowRiwayatLatihan: 10,
    showModalLatihan: false,
    showModalUjian: false,
  },
  getDetailPelatihan: () => {},
  setMateri: () => {},
  setIndexData: () => {},
  setIndexMateri: () => {},
  handleNextSubModul: () => {},
  getLogLatihan: () => {},
  setStatusLatihan: () => {},
  setIndexPathMateri: () => {},
  setInitialTimeVideo: () => {},
  setProgressTime: () => {},
  setPageRowRiwayatLatihan: () => {},
  setShowModalLatihan: () => {},
  setShowModalUjian: () => {},
};
const Context = React.createContext<InitialState>(initialState);
const { Provider: PelatihanDetailProvider } = Context;

const Provider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<IState>({
    isLoading: true,
    isLoadingNext: false,
    isLoadingLog: true,
    pelatihan: null,
    indexData: 0,
    indexMateri: 0,
    pathMateri: [],
    judulMateri: "",
    uraianDeskripsi: "",
    views: 0,
    tanggalPembuatan: "",
    ada_latihan: 0,
    judulSubModul: "",
    jenisSoal: "",
    jumlahSoal: 0,
    latihan_id: 0,
    status_latihan: "",
    logLatihanData: [],
    indexPathMateri: 0,
    statusKonsultasi: 0,
    initialTimeVideo: 0,
    progressTime: 0,
    statusSubModul: "",
    pageRowRiwayatLatihan: 10,
    showModalLatihan: false,
    showModalUjian: false,
  });

  // use is authenticated
  const isAuthenticated = useIsAuthenticated();

  const setShowModalUjian = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showModalUjian: value,
    }));
  };

  const setShowModalLatihan = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      showModalLatihan: value,
    }));
  };

  const setLoading = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoading: value,
    }));
  };

  const setLoadingNext = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingNext: value,
    }));
  };

  const setLoadingLog = (value: boolean) => {
    setState((prevstate) => ({
      ...prevstate,
      isLoadingLog: value,
    }));
  };

  const setStatusLatihan = (value: string) => {
    setState((prevstate) => ({
      ...prevstate,
      status_latihan: value,
    }));
  };

  const setPageRowRiwayatLatihan = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      pageRowRiwayatLatihan: value,
    }));
  };

  const setIndexData = (index: number) => {
    setState((prevState) => ({
      ...prevState,
      indexData: index,
    }));
  };

  const setIndexPathMateri = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      indexPathMateri: value,
    }));
  };

  const setInitialTimeVideo = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      initialTimeVideo: value,
    }));
  };

  const setProgressTime = (value: number) => {
    setState((prevstate) => ({
      ...prevstate,
      progressTime: value,
    }));
  };

  const handleChangeMateri = async (index: number) => {
    setState((prevState) => ({
      ...prevState,
      pathMateri: [],
      judulMateri: "",
      uraianDeskripsi: "",
      views: 0,
      tanggalPembuatan: "",
      statusKonsultasi: 0,
    }));

    if (state.pelatihan != null) {
      const materi: any =
        state.pelatihan.subModulPelatihan[state.indexData].m_materi[index]
          .materi;

      // Get Path Materi
      const pathMateriPresenter = container.resolve(PathMateriPresenter);
      const get = await pathMateriPresenter.getAllPathMateri(
        materi.materi_id,
        ""
      );

      setState((prevState) => ({
        ...prevState,
        pathMateri: get,
        judulMateri: materi.judul_materi,
        uraianDeskripsi: materi.uraian_deskripsi,
        views: materi.views,
        tanggalPembuatan: materi.tanggal_pembuatan,
        statusKonsultasi: materi.status_konsultasi,
      }));
    }
  };

  const setIndexMateri = (index: number) => {
    handleChangeMateri(index);

    setState((prevState) => ({
      ...prevState,
      indexMateri: index,
    }));
  };

  // get detail pelatihan
  const getDetailPelatihan = async (id: number) => {
    setLoading(true);

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const getDetail = await pelatihanPresenter.getDetailPelatihan(id);
      const data = getDetail;

      if (isAuthenticated() != false) {
        if (data.status_pelatihan == "Sedang Mengikuti") {
          if (data.modulPelatihan.length > 0) {
            data.modulPelatihan.map(async (val) => {
              const sub_modul_data = val.sub_modul.find(
                (x) => x.status_pembelajaran == "Sedang Mengikuti"
              );
              const indexdata = data.subModulPelatihan.findIndex(
                (x) => x.id == sub_modul_data?.id
              );
              if (indexdata != -1) {
                setIndexData(indexdata % data.subModulPelatihan.length);
              }
              if (sub_modul_data) {
                if (sub_modul_data.m_materi.length > 0) {
                  // Get Path Materi
                  const pathMateriPresenter =
                    container.resolve(PathMateriPresenter);
                  const get = await pathMateriPresenter.getAllPathMateri(
                    sub_modul_data.m_materi[0].materi_id,
                    ""
                  );
                  setState((prevstate) => ({
                    ...prevstate,
                    pelatihan: data,
                    pathMateri: get,
                    judulMateri: sub_modul_data.m_materi[0].materi.judul_materi,
                    statusKonsultasi:
                      sub_modul_data.m_materi[0].materi.status_konsultasi,
                    uraianDeskripsi:
                      sub_modul_data.m_materi[0].materi.uraian_deskripsi,
                    views: sub_modul_data.m_materi[0].materi.views,
                    tanggalPembuatan:
                      sub_modul_data.m_materi[0].materi.tanggal_pembuatan,
                    ada_latihan: sub_modul_data.ada_latihan,
                    judulSubModul: sub_modul_data.judul,
                    jenisSoal:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.m_latihan[0].m_paket_soal[0].jenis_soal
                        : "",
                    jumlahSoal:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.limit_soal
                        : 0,
                    latihan_id:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.m_latihan[0].id
                        : 0,
                    status_latihan:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.m_latihan[0].status
                        : "",
                    statusSubModul: sub_modul_data.status_pembelajaran,
                  }));
                } else {
                  setState((prevstate) => ({
                    ...prevstate,
                    pelatihan: data,
                    pathMateri: [],
                    statusKonsultasi: 0,
                    judulMateri: "",
                    uraianDeskripsi: "",
                    views: 0,
                    tanggalPembuatan: "",
                    ada_latihan: sub_modul_data.ada_latihan,
                    judulSubModul: sub_modul_data.judul,
                    jenisSoal:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.m_latihan[0].m_paket_soal[0].jenis_soal
                        : "",
                    jumlahSoal:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.limit_soal
                        : 0,
                    latihan_id:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.m_latihan[0].id
                        : 0,
                    status_latihan:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.m_latihan[0].status
                        : "",
                    statusSubModul: sub_modul_data.status_pembelajaran,
                  }));
                }
                localStorage.setItem(
                  "id_subModul",
                  sub_modul_data.id.toString()
                );
              } else {
                setState((prevstate) => ({
                  ...prevstate,
                  pelatihan: data,
                }));
              }
            });
          } else {
            setState((prevstate) => ({
              ...prevstate,
              pelatihan: data,
            }));
          }
        } else {
          if (data.modulPelatihan.length > 0) {
            data.modulPelatihan.map(async (val) => {
              const sub_modul_data = val.sub_modul[0];
              if (sub_modul_data) {
                if (sub_modul_data.m_materi.length > 0) {
                  // Get Path Materi
                  const pathMateriPresenter =
                    container.resolve(PathMateriPresenter);
                  const get = await pathMateriPresenter.getAllPathMateri(
                    sub_modul_data.m_materi[0].materi_id,
                    ""
                  );
                  setState((prevstate) => ({
                    ...prevstate,
                    pelatihan: data,
                    pathMateri: get,
                    judulMateri: sub_modul_data.m_materi[0].materi.judul_materi,
                    statusKonsultasi:
                      sub_modul_data.m_materi[0].materi.status_konsultasi,
                    uraianDeskripsi:
                      sub_modul_data.m_materi[0].materi.uraian_deskripsi,
                    views: sub_modul_data.m_materi[0].materi.views,
                    tanggalPembuatan:
                      sub_modul_data.m_materi[0].materi.tanggal_pembuatan,
                    ada_latihan: sub_modul_data.ada_latihan,
                    judulSubModul: sub_modul_data.judul,
                    jenisSoal:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.m_latihan[0].m_paket_soal[0].jenis_soal
                        : "",
                    jumlahSoal:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.limit_soal
                        : 0,
                    latihan_id:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.m_latihan[0].id
                        : 0,
                    status_latihan:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.m_latihan[0].status
                        : "",
                    statusSubModul: sub_modul_data.status_pembelajaran,
                  }));
                } else {
                  setState((prevstate) => ({
                    ...prevstate,
                    pelatihan: data,
                    pathMateri: [],
                    statusKonsultasi: 0,
                    judulMateri: "",
                    uraianDeskripsi: "",
                    views: 0,
                    tanggalPembuatan: "",
                    ada_latihan: sub_modul_data.ada_latihan,
                    judulSubModul: sub_modul_data.judul,
                    jenisSoal:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.m_latihan[0].m_paket_soal[0].jenis_soal
                        : "",
                    jumlahSoal:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.limit_soal
                        : 0,
                    latihan_id:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.m_latihan[0].id
                        : 0,
                    status_latihan:
                      sub_modul_data.m_latihan.length > 0
                        ? sub_modul_data.m_latihan[0].status
                        : "",
                    statusSubModul: sub_modul_data.status_pembelajaran,
                  }));
                }
                localStorage.setItem(
                  "id_subModul",
                  sub_modul_data.id.toString()
                );
              } else {
                setState((prevstate) => ({
                  ...prevstate,
                  pelatihan: data,
                }));
              }
            });
          } else {
            setState((prevstate) => ({
              ...prevstate,
              pelatihan: data,
            }));
          }
        }
      } else {
        setState((prevstate) => ({
          ...prevstate,
          pelatihan: data,
        }));
      }

      setLoading(false);
    } catch (error: any) {
      console.log("error get pelatihan:", error);
      setLoading(false);
    }
  };

  // set materi
  const setMateri = async (
    id_materi: number,
    id_submodul: number,
    judul_materi: string,
    uraian_deskripsi: string,
    views: number,
    tanggal_pembuatan: string,
    status_konsultasi: number
  ) => {
    setLoading(true);

    const data_pelatihan = state.pelatihan;

    setState((prevState) => ({
      ...prevState,
      pathMateri: [],
      indexPathMateri: 0,
      judulMateri: "",
      uraianDeskripsi: "",
      statusKonsultasi: 0,
      views: 0,
      tanggalPembuatan: "",
      ada_latihan: 0,
      judulSubModul: "",
      jenisSoal: "",
      jumlahSoal: 0,
      latihan_id: 0,
      status_latihan: "",
      statusSubModul: "",
      logLatihanData: [],
    }));

    try {
      if (data_pelatihan != null) {
        const index_subModul = data_pelatihan.subModulPelatihan.findIndex(
          (v) => v.id == id_submodul
        );

        if (index_subModul != -1) {
          setIndexData(
            index_subModul % data_pelatihan.subModulPelatihan.length
          );

          const sub_modul: any =
            data_pelatihan.subModulPelatihan[index_subModul];

          // Get Path Materi
          const pathMateriPresenter = container.resolve(PathMateriPresenter);
          const get = await pathMateriPresenter.getAllPathMateri(id_materi, "");

          setState((prevState) => ({
            ...prevState,
            pathMateri: get,
            judulMateri: judul_materi,
            uraianDeskripsi: uraian_deskripsi,
            statusKonsultasi: status_konsultasi,
            views: views,
            tanggalPembuatan: tanggal_pembuatan,
            ada_latihan:
              data_pelatihan.subModulPelatihan[index_subModul].ada_latihan,
            judulSubModul:
              data_pelatihan.subModulPelatihan[index_subModul].judul,
            jenisSoal:
              sub_modul.m_latihan.length > 0
                ? sub_modul.m_latihan[0].m_paket_soal[0].jenis_soal
                : "",
            jumlahSoal:
              sub_modul.m_latihan.length > 0 ? sub_modul.limit_soal : 0,
            latihan_id:
              sub_modul.m_latihan.length > 0 ? sub_modul.m_latihan[0].id : 0,
            status_latihan:
              sub_modul.m_latihan.length > 0
                ? sub_modul.m_latihan[0].status
                : "",
            statusSubModul: sub_modul.status_pembelajaran,
          }));
        }
      }

      localStorage.setItem("id_subModul", id_submodul.toString());

      setLoading(false);
    } catch (error: any) {
      console.log("error set materi:", error);
      setLoading(false);
    }
  };

  // handle next sub modul
  const handleNextSubModul = async (sub_modul_id: number) => {
    setLoadingNext(true);

    try {
      const pelatihanPresenter = container.resolve(PelatihanPresenter);
      const store = await pelatihanPresenter.storeStatusSubModul(sub_modul_id);

      const data = store;

      return data.data.code;
    } catch (error: any) {
      console.log("error store pelatihan:", error.message);
      setLoadingNext(false);
    }
  };

  // get log latihan
  const getLogLatihan = async (
    page: number,
    row: number,
    latihan_id: number
  ) => {
    setLoadingLog(true);

    try {
      const logLatihanPresenter = container.resolve(LogLatihanPresenter);
      const get = await logLatihanPresenter.getLogLatihan(
        page,
        row,
        latihan_id
      );

      const data = get;

      setState((prevstate) => ({
        ...prevstate,
        logLatihanData: data,
      }));

      setLoadingLog(false);
    } catch (error: any) {
      console.log("error get log:", error.message);
      setLoadingLog(false);
    }
  };

  return (
    <PelatihanDetailProvider
      value={{
        state,
        getDetailPelatihan,
        setMateri,
        setIndexData,
        setIndexMateri,
        handleNextSubModul,
        getLogLatihan,
        setStatusLatihan,
        setIndexPathMateri,
        setInitialTimeVideo,
        setProgressTime,
        setPageRowRiwayatLatihan,
        setShowModalLatihan,
        setShowModalUjian,
      }}
    >
      {children}
    </PelatihanDetailProvider>
  );
};

export const useDetailPelatihanContext = () => React.useContext(Context);

export default {
  useDetailPelatihanContext,
  Provider,
};
