import { useToast } from "@chakra-ui/react";
import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../../../api/axiosInstance";
import { parse, format } from "date-fns";

export type OptionType = {
  label: string;
  value: string;
};

export interface FileAssesment {
  id: number;
  assessment_id: number;
  path_file: string;
  nama_file: string;
  mime_type: string;
  created_at: string;
  updated_at: string;
}

interface IState {
  state: {
    isOpenModal: boolean;
    speakerName: string;
    title: string;
    linkStreaming: string;
    pathFile: File | null;
    titleAssesment: string;
    descriptionAssesment: string;
    pathFileAssesment: File[] | FileAssesment[];
    linkDrive: string;
    paketSoalId: string;
    quizDuration: number;
    category: OptionType | null;
    youtubeVideoSelected: OptionType | null;
    pdf: File | null;
    isLoadingPost: boolean;
    url: string;
    keyword: string;
    isLoadingData: boolean;
    listData: [];
    listSpeakers: OptionType[];
    isAddingNewSpeaker: boolean;
    speakerSelected: OptionType | null;
    page: number;
    perPage: number;
    totalListData: number;
    hasQuiz: boolean;
    hasAssesment: boolean;
    mode: string;
    listCategory: OptionType[];
    modalQuizMode: string;
    modalAssesmentMode: string;
    startDate: string;
    startTime: string;
  };
  disptach: {
    setPengetahuanKontenPemateri: Function;
    postData: Function;
    fetchData: Function;
    clearState: Function;
    setPathFileAssesment: Function;
    getDetailKontenPemateri: Function;
  };
}

const initialState: IState = {
  state: {
    isOpenModal: false,
    speakerName: "",
    title: "",
    linkStreaming: "",
    pathFile: null,
    titleAssesment: "",
    descriptionAssesment: "",
    pathFileAssesment: [],
    linkDrive: "",
    paketSoalId: "",
    quizDuration: 0,
    category: null,
    youtubeVideoSelected: null,
    pdf: null,
    isLoadingPost: false,
    url: "management_content/pengetahuan_materi",
    keyword: "",
    isLoadingData: false,
    listData: [],
    listSpeakers: [],
    isAddingNewSpeaker: false,
    speakerSelected: null,
    page: 1,
    perPage: 10,
    totalListData: 0,
    hasAssesment: false,
    hasQuiz: false,
    mode: "view",
    listCategory: [
      { value: "video", label: "Video" },
      { value: "pdf", label: "PDF" },
    ],
    modalQuizMode: "",
    modalAssesmentMode: "",
    startDate: "",
    startTime: "",
  },
  disptach: {
    setPengetahuanKontenPemateri: () => {},
    postData: () => {},
    fetchData: () => {},
    clearState: () => {},
    setPathFileAssesment: () => {},
    getDetailKontenPemateri: () => {},
  },
};

const Context = createContext<IState>(initialState);
const { Provider: ManajemenPengetahuanKontenPemateriProvider } = Context;

const ManajemenPengetahuanKontenPemateriController: React.FC = ({
  children,
}) => {
  const [state, setState] = useState<IState>(initialState);
  const toast = useToast();
  const history = useHistory();

  const {
    speakerName,
    title,
    linkStreaming,
    pathFile,
    linkDrive,
    paketSoalId,
    quizDuration,
    titleAssesment,
    descriptionAssesment,
    url,
    category,
    youtubeVideoSelected,
    pdf,
    isAddingNewSpeaker,
    speakerSelected,
    keyword,
    page,
    perPage,
    listCategory,
    pathFileAssesment,
    hasQuiz,
    hasAssesment,
    startDate,
    startTime,
  } = state.state;

  const setPengetahuanKontenPemateri = (
    key: keyof IState["state"],
    value: any
  ) => {
    setState((prevState) => ({
      ...prevState,
      state: {
        ...prevState.state,
        [key]: value,
      },
    }));
  };

  const fetchData = async (id: number) => {
    setPengetahuanKontenPemateri("isLoadingData", true);

    try {
      const { data } = await axiosInstance.get(url, {
        params: {
          page,
          per_page: perPage,
          pengetahuan_id: id,
          search: keyword,
        },
      });

      setPengetahuanKontenPemateri(
        "listData",
        data.data.pengetahuanMateri?.data ?? []
      );

      setPengetahuanKontenPemateri(
        "totalListData",
        data.data.pengetahuanMateri?.total
      );

      const dataSpeaker = data.data.pemateri.map((item) => ({
        label: item.nama,
        value: item.id,
      }));
      setPengetahuanKontenPemateri("listSpeakers", dataSpeaker);
    } catch (error) {
      throw error;
    } finally {
      setPengetahuanKontenPemateri("isLoadingData", false);
    }
  };

  const getDetailKontenPemateri = async (id: number) => {
    setPengetahuanKontenPemateri("isLoadingData", true);
    try {
      const { data } = await axiosInstance.get(`${url}/${id}`);

      const { pengetahuanMateri, pemateri } = data?.data;

      const speakerSelected = pemateri
        .filter((item) => item.id === pengetahuanMateri?.pemateri_id)
        .map((item) => ({
          label: item.nama,
          value: item.id,
        }))[0];

      const listSpeakersUpdated = pemateri.map((item) => ({
        label: item.nama,
        value: item.id,
      }));
      setPengetahuanKontenPemateri("listSpeakers", listSpeakersUpdated);
      setPengetahuanKontenPemateri("speakerSelected", speakerSelected);
      setPengetahuanKontenPemateri("title", pengetahuanMateri?.judul);

      if (pengetahuanMateri?.tanggal_mulai) {
        const startDateMateri = parse(
          pengetahuanMateri?.tanggal_mulai,
          "d MMMM yyyy",
          new Date()
        );
        const convertStartDate = format(startDateMateri, "yyyy-MM-dd");
        setPengetahuanKontenPemateri("startDate", convertStartDate);
      }

      if (pengetahuanMateri?.waktu_mulai) {
        const startTimeMateri = parse(
          pengetahuanMateri?.waktu_mulai,
          "hh:mm a",
          new Date()
        );
        const convertStartTime = format(startTimeMateri, "HH:mm");
        setPengetahuanKontenPemateri("startTime", convertStartTime);
      }

      setPengetahuanKontenPemateri(
        "linkStreaming",
        pengetahuanMateri?.link_streaming
      );
      const categorySelected = listCategory.find(
        (item) => item.value === pengetahuanMateri?.category
      );
      setPengetahuanKontenPemateri("category", categorySelected);

      if (categorySelected?.value === "video") {
        setPengetahuanKontenPemateri(
          "youtubeVideoSelected",
          pengetahuanMateri?.path_file
        );
      } else {
        setPengetahuanKontenPemateri("pdf", pengetahuanMateri?.path_file);
      }

      if (pengetahuanMateri?.has_quiz) {
        setPengetahuanKontenPemateri("hasQuiz", pengetahuanMateri?.has_quiz);
        setPengetahuanKontenPemateri(
          "paketSoalId",
          pengetahuanMateri?.paket_soal_id
        );
        setPengetahuanKontenPemateri(
          "quizDuration",
          pengetahuanMateri?.durasi_quiz
        );
      }

      if (pengetahuanMateri?.has_assessment) {
        setPengetahuanKontenPemateri(
          "hasAssesment",
          pengetahuanMateri?.has_assessment
        );
        setPengetahuanKontenPemateri(
          "titleAssesment",
          pengetahuanMateri?.pengetahuan_assessment?.judul
        );
        setPengetahuanKontenPemateri(
          "descriptionAssesment",
          pengetahuanMateri?.pengetahuan_assessment?.keterangan
        );

        const updatedPathFilesAssesment =
          pengetahuanMateri?.pengetahuan_assessment?.assessment_files.map(
            (item) => {
              const { nama_file, ...rest } = item;
              return {
                name: nama_file,
                ...rest,
              };
            }
          );

        const hasLInkDrive = updatedPathFilesAssesment?.find(
          (item) => item?.link_drive !== null
        );

        setPengetahuanKontenPemateri(
          "pathFileAssesment",
          updatedPathFilesAssesment
        );

        setPengetahuanKontenPemateri(
          "linkDrive",
          hasLInkDrive?.link_drive ?? ""
        );
      }
    } catch (error) {
      throw error;
    } finally {
      setPengetahuanKontenPemateri("isLoadingData", false);
    }
  };

  const clearState = () => {
    setPengetahuanKontenPemateri("isOpenModal", false);
    setPengetahuanKontenPemateri("title", "");
    setPengetahuanKontenPemateri("category", null);
    setPengetahuanKontenPemateri("youtubeVideoSelected", null);
    setPengetahuanKontenPemateri("pdf", null);
    setPengetahuanKontenPemateri("hasAssesment", false);
    setPengetahuanKontenPemateri("hasQuiz", false);
    setPengetahuanKontenPemateri("paketSoalId", null);
    setPengetahuanKontenPemateri("quizDuration", 0);
    setPengetahuanKontenPemateri("titleAssesment", "");
    setPengetahuanKontenPemateri("descriptionAssesment", "");
    setPengetahuanKontenPemateri("pathFileAssesment", []);
    setPengetahuanKontenPemateri("speakerSelected", null);
    setPengetahuanKontenPemateri("speakerName", "");
    setPengetahuanKontenPemateri("isAddingNewSpeaker", false);
    setPengetahuanKontenPemateri("linkStreaming", "");
    setPengetahuanKontenPemateri("linkDrive", "");
  };

  // Helper function to show error toasts
  const showErrorToast = (title: string, description?: string) => {
    toast({
      title,
      description,
      status: "error",
      position: "top-right",
      duration: 3000,
      isClosable: true,
    });
  };

  // Combined function to validate both quiz and assessment fields
  const validateQuizAndAssessment = () => {
    const validationErrors: string[] = [];

    // Quiz validation
    if (hasQuiz) {
      if (!paketSoalId) {
        validationErrors.push("Paket soal ID tidak boleh kosong");
      }
      if (!quizDuration || quizDuration <= 0) {
        validationErrors.push("Durasi kuis harus lebih dari 0");
      }
    }

    // Assessment validation
    if (hasAssesment) {
      if (!titleAssesment) {
        validationErrors.push("Judul assignment tidak boleh kosong");
      }
      if (!descriptionAssesment) {
        validationErrors.push("Deskripsi assignment tidak boleh kosong");
      }
      if (!linkDrive) {
        validationErrors.push("Link assignment tidak boleh kosong");
      }
      if (pathFileAssesment.length === 0) {
        validationErrors.push("Bahan tugas tidak boleh kosong");
      }
    }

    // Show all collected validation errors
    validationErrors.forEach((message) => showErrorToast("Error", message));

    if (validationErrors.length > 0) {
      return false;
    }
    return true;
  };

  const postData = async (id: number, idPemateri: number | null = null) => {
    // Validate both quiz and assessment fields
    const validate = validateQuizAndAssessment();

    if (!validate) {
      return;
    }

    setPengetahuanKontenPemateri("isLoadingPost", true);

    const formData = new FormData();
    formData.append("pengetahuan_id", id.toString());
    if (isAddingNewSpeaker) {
      formData.append("nama_pemateri", speakerName);
    } else {
      if (speakerSelected) {
        formData.append("nama_pemateri", speakerSelected?.label);
      }
    }

    formData.append("judul", title);
    if (startDate) {
      formData.append("tanggal_mulai", startDate);
    }
    if (startTime) {
      formData.append("waktu_mulai", startTime);
    }
    if (linkStreaming) {
      formData.append("link_streaming", linkStreaming);
      formData.append("category", "streaming");
    }
    if (paketSoalId && hasQuiz) {
      formData.append("paket_soal_id", paketSoalId);
      formData.append("durasi_quiz", quizDuration.toString());
    }

    if (hasAssesment) {
      formData.append("judul_asesmen", titleAssesment);
      formData.append("keterangan_asesmen", descriptionAssesment);
      formData.append("link_drive", linkDrive);

      if (pathFileAssesment.length) {
        pathFileAssesment.forEach((file, index) => {
          if (file instanceof File) {
            formData.append(`path_file_assessment[${index}]`, file);
          } else {
            formData.append(`path_file_assessment[${index}]`, file.path_file);
          }
        });
      }
    }

    if (category) {
      formData.append("category", category.value);
      if (youtubeVideoSelected && category.value === "video") {
        if (typeof youtubeVideoSelected === "string") {
          formData.append("path_file", youtubeVideoSelected);
        } else {
          formData.append("path_file", youtubeVideoSelected.value);
        }
      }
      if (pdf && category.value === "pdf") {
        formData.append("path_file", pdf);
      }
    }

    if (pathFile) {
      formData.append("path_file", pathFile);
    }

    const urlAction = idPemateri === null ? url : `${url}/${idPemateri}`;

    try {
      const { data } = await axiosInstance.post(urlAction, formData);

      if (data.code === 200) {
        toast({
          title:
            data.message ??
            "Penegetahuan konten pemateri baru berhasil ditambahkan",
          status: "success",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
        clearState();
        if (idPemateri) {
          history.push(`/admin/manajemen_pengetahuan/${id}`);
        }
        fetchData(id);
      }
    } catch (error) {
      const responseData = error.response?.data;
      const titleMessage = responseData.message;
      if (responseData.code === 422) {
        Object.keys(responseData.errors).forEach((key) => {
          responseData.errors[key].forEach((errorMessage) => {
            toast({
              title: titleMessage,
              description: errorMessage,
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          });
        });
      }

      toast({
        title: titleMessage,
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setPengetahuanKontenPemateri("isLoadingPost", false);
    }
  };

  const setPathFileAssesment = (files: File[]) => {
    setPengetahuanKontenPemateri("pathFileAssesment", files);
  };

  return (
    <ManajemenPengetahuanKontenPemateriProvider
      value={{
        state: state.state,
        disptach: {
          setPengetahuanKontenPemateri,
          postData,
          fetchData,
          clearState,
          setPathFileAssesment,
          getDetailKontenPemateri,
        },
      }}
    >
      {children}
    </ManajemenPengetahuanKontenPemateriProvider>
  );
};

export const useManajemenPengetahuanKontenPemateriContext = () => {
  return useContext(Context);
};

export default ManajemenPengetahuanKontenPemateriController;
