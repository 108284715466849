import { Switch, BrowserRouter, Route } from "react-router-dom";
import Login from "./app/ui/pages/auth/login";
import ForgotPassword from "./app/ui/pages/auth/forgot_password";
import Register from "./app/ui/pages/auth/register";
import Faq from "./app/ui/pages/faq";
import Profile from "./app/ui/pages/profile";
import PelatihanList from "./app/ui/pages/pelatihan/index";
import PelatihanDetails from "./app/ui/pages/pelatihan/view";
import Pengetahuan from "./app/ui/pages/pengetahuan/index";
import PengetahuanDetails from "./app/ui/pages/pengetahuan/detail";
import AdminDashboard from "./app/ui/pages/admin/dashboard";
import ManajemenSoal from "./app/ui/pages/admin/manajemen_soal/index";
import ManajemenSoalDetail from "./app/ui/pages/admin/manajemen_soal/detail";
import FormManajemenSoal from "./app/ui/pages/admin/manajemen_soal/create";
import ManajemenMateri from "./app/ui/pages/admin/manajemen_materi/index";
import ManajemenPelatihan from "./app/ui/pages/admin/manajemen_pelatihan/index";
import ManajemenMateriDetail from "./app/ui/pages/admin/manajemen_materi/detail";
import ManajemenMateriTambah from "./app/ui/pages/admin/manajemen_materi/create";
import CreateManajemenPelatihan from "./app/ui/pages/admin/manajemen_pelatihan/create";
import KelolaKontenManajemenPelatihan from "./app/ui/pages/admin/manajemen_pelatihan/kelola_konten";
import DetailSoalManajemenPelatihan from "./app/ui/pages/admin/manajemen_pelatihan/detail_soal";
import UpdateManajemenSoal from "./app/ui/pages/admin/manajemen_soal/update";
import AccessNotFound from "./app/ui/pages/default/access_not_found";
import NotFoundPage from "./app/ui/pages/default/not_found_page";
import PenilaianManajemenPelatihan from "./app/ui/pages/admin/manajemen_pelatihan/penilaian";
import MasterKategoriSoal from "./app/ui/pages/admin/data_master/kategori_soal";
import ManajemenMateriUbah from "./app/ui/pages/admin/manajemen_materi/update";
import DetailDataPelatihanManajemenPelatihan from "./app/ui/pages/admin/manajemen_pelatihan/detail/data_pelatihan";
import UpdateManajemenPelatihan from "./app/ui/pages/admin/manajemen_pelatihan/update";
import MasterJenisPelatihan from "./app/ui/pages/admin/data_master/jenis_pelatihan";
import PelatihanSayaDetails from "./app/ui/pages/pelatihan/detail";
import MasterFaq from "./app/ui/pages/admin/data_master/faq";
import MasterSlider from "./app/ui/pages/admin/data_master/slider";
import ManajemenUser from "./app/ui/pages/admin/manajemen_user";
import DetailKontenPelatihanManajemenPelatihan from "./app/ui/pages/admin/manajemen_pelatihan/detail/konten_pelatihan";
import DetailPenilaianManajemenPelatihan from "./app/ui/pages/admin/manajemen_pelatihan/detail/penilaian";
import DetailDaftarPesertaManajemenPelatihan from "./app/ui/pages/admin/manajemen_pelatihan/detail/daftar_peserta";
import LatihanExam from "./app/ui/pages/latihan/index";
import Skor from "./app/ui/pages/latihan/skor";
import ProjectView from "./app/ui/pages/project/index";
import ContactUs from "./app/ui/pages/contact_us/index";
import HelpdeskKonsultasi from "./app/ui/pages/admin/helpdesk/konsultasi";
import MasterProvinsi from "./app/ui/pages/admin/data_master/provinsi";
import MasterKabupaten from "./app/ui/pages/admin/data_master/kabupaten";
import MasterKecamatan from "./app/ui/pages/admin/data_master/kecamatan";
import MasterKelurahan from "./app/ui/pages/admin/data_master/kelurahan";
import UjianExam from "./app/ui/pages/ujian/index";
import HelpdeskBantuan from "./app/ui/pages/admin/helpdesk/bantuan";
import ConfirmationPasswordForgot from "./app/ui/pages/auth/confirmation_password";
import PageMainAfterLogin from "./app/ui/pages/main/login_user";
import PageMainBeforeLogin from "./app/ui/pages/main/public_user";
import ManajemenSertifikat from "./app/ui/pages/admin/manajemen_sertifikat";
import ManajemenAlumniSimdiklat from "./app/ui/pages/admin/manajemen_alumni_simdiklat";
import React from "react";
import { AuthProvider, useAuthUser } from "react-auth-kit";
import AuthWrapper from "./app/ui/components/Auth/AuthWrapper";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import Podcast from "./app/ui/pages/podcast";
import AdminSlideBanner from "./app/ui/pages/admin/landing_page/slide_banner";
import AdminInfografis from "./app/ui/pages/admin/landing_page/infografis";
import AdminEventPintar from "./app/ui/pages/admin/landing_page/event_pintar";
import ManajemenPengetahuan from "./app/ui/pages/admin/manajemen_pengetahuan";
import ManajemenPengetahuanDetail from "./app/ui/pages/admin/manajemen_pengetahuan/detail";
import ManajemenPodcast from "./app/ui/pages/admin/manajemen_podcast";
import PodcastCategory from "./app/ui/pages/admin/data_master/kategori_podcast";
import VideoCourseCategory from "./app/ui/pages/admin/data_master/kategori_kursus_video";
import DetailEventPintar from "./app/ui/pages/event_pintar/detail";
import RedirectPage from "./app/ui/pages/redirect";
import ManajemenIdentitas from "./app/ui/pages/admin/landing_page/manajemen_identitas";
import RequestAccess from "./app/ui/pages/require-access";
import MasterTTE from "./app/ui/pages/admin/data_master/tte";
import PengetahuanQuiz from "./app/ui/pages/quiz";
import MasterKategoriPengetahuan from "./app/ui/pages/admin/data_master/kategori_pengetahuan";
import RiwayatRapotPengetahaunPage from "./app/ui/pages/pengetahuan/riwayat_rapot";
import Kontributor from "./app/ui/pages/kontributor";
import KontributorPengajuan from "./app/ui/pages/kontributor/pengajuan";
import KontributorStatusPengajuan from "./app/ui/pages/kontributor/status_pengajuan";
import ManajemenKontributorPengetahuan from "./app/ui/pages/admin/manajemen_kontributor_pengetahuan";
import VerifikatorKontributorPengetahuan from "./app/ui/pages/admin/manajemen_kontributor_pengetahuan/verifikator";
import DetailKontenPemateri from "./app/ui/pages/admin/manajemen_pengetahuan/detail/konten_pemateri";
import PreviewRapot from "./app/ui/pages/pengetahuan/preview-rapot";

import axios from "axios";

import { useSignIn } from "react-auth-kit";

// env
import env from "@ludovicm67/react-dotenv";

// Keycloak
import { KeycloakProvider, useKeycloak } from "keycloak-react-web";
import Keycloak from "keycloak-js";

// SSO Simpatika Provider
import SsoSimpatikaController from "./app/ui/controller/auth/sso_simpatika";
import KlinikPengetahuan from "./app/ui/pages/pengetahuan/klinik-pengetahuan";
import DaftarPengetahuan from "./app/ui/pages/pengetahuan/daftar-pengetahuan";

const keycloakSetting = {
  url: "https://keycloak.impstudio.id",
  realm: "Pusaka",
  clientId: "Pintar",
};

const authInstance = new Keycloak(keycloakSetting);

const generateRoutes = (authData, isAllPath) => {
  const level = authData?.level;

  const commonRoutes = [
    // Common routes here (e.g., login, register, home, etc.)
    {
      name: "Redirect",
      component: RedirectPage,
      path: "/redirect",
      exact: false,
    },
    {
      name: "Login",
      component: Login,
      path: "/login",
      exact: false,
    },
    {
      name: "Reset Password",
      component: ForgotPassword,
      path: "/reset_password",
      exact: false,
    },
    {
      name: "Reset Password Confirmation",
      component: ConfirmationPasswordForgot,
      path: "/password_reset/:id",
      exact: false,
    },
    {
      name: "Register",
      component: Register,
      path: "/register",
      exact: false,
    },
    {
      name: "Request Acces",
      component: RequestAccess,
      path: "/require_access",
      exact: false,
    },
    {
      name: "Kontributor",
      component: Kontributor,
      path: "/kontributor",
      exact: true,
    },
    {
      name: "Kontributor Pengajuan",
      component: KontributorPengajuan,
      path: "/kontributor/pengajuan",
      exact: false,
    },
    {
      name: "Cek Status  Pengajuan",
      component: KontributorStatusPengajuan,
      path: "/kontributor/cek-status-pengajuan/:nomorPengajuan",
      exact: false,
    },
  ];

  const userSpecificRoutes = [
    {
      name: "Home",
      component: authData !== null ? PageMainAfterLogin : PageMainBeforeLogin,
      path: "/",
      exact: true,
    },
    // Pelatihan Route
    {
      name: "Pelatihan",
      component: PelatihanList,
      path: "/pelatihan",
      exact: false,
    },
    {
      name: "PelatihanDetails",
      component: PelatihanDetails,
      path: "/detail/pelatihan/:id",
      exact: false,
    },
    // Pelatihan Route

    // Pengetahuan Route
    {
      name: "Pengetahuan",
      component: Pengetahuan,
      path: "/pengetahuan",
      exact: true,
    },
    {
      name: "Klinik Pengetahuan",
      component: KlinikPengetahuan,
      path: "/klinik-pengetahuan",
      exact: false,
    },
    {
      name: "Daftar Pengetahuan",
      component: DaftarPengetahuan,
      path: "/daftar-pengetahuan",
      exact: false,
    },
    {
      name: "PengetahuanDetails",
      component: PengetahuanDetails,
      path: "/detail/pengetahuan/:id",
      exact: false,
    },
    // Pengetahuan Route

    // Podcast Route
    {
      name: "Podcast",
      component: Podcast,
      path: "/podcast",
      exact: false,
    },

    {
      name: "Faq",
      component: Faq,
      path: "/faq",
      exact: false,
    },

    {
      name: "Profile",
      component: Profile,
      path: "/profile",
      exact: false,
    },
    {
      name: "Skor",
      component: Skor,
      path: "/skor/:id",
      exact: false,
    },
    {
      name: "Hubungi Kami",
      component: ContactUs,
      path: "/contact_us",
      exact: false,
    },
    {
      name: "Project",
      component: ProjectView,
      path: "/send_project/:id",
      exact: false,
    },

    {
      name: "Detail Pelatihan Saya",
      component: PelatihanSayaDetails,
      path: "/detail_pelatihan_saya/:id",
      exact: false,
    },
    {
      name: "Exam View",
      component: LatihanExam,

      path: "/latihan/:id",
      exact: false,
    },

    {
      name: "Ujian View",
      component: UjianExam,
      path: "/ujian/:id",
      exact: false,
    },

    {
      name: "DetailEventPintar",
      component: DetailEventPintar,
      path: "/detail/event-pintar/:id",
      exact: false,
    },

    {
      name: "Pengetahuan Quiz",
      component: PengetahuanQuiz,
      path: "/quiz/:pengetahuanId",
      exact: false,
    },
    {
      name: "Pengetahuan Riwayat Rapot",
      component: RiwayatRapotPengetahaunPage,
      path: "/pengetahuan/riwayat-rapot",
      exact: false,
    },
    {
      name: "Pengetahuan  Preview Rapot",
      component: PreviewRapot,
      path: "/pengetahuan/preview-rapot",
      exact: false,
    },
  ];

  const adminSpecificRoutes = [
    {
      name: "Dashboard",
      component: AdminDashboard,
      path: "/",
      exact: true,
    },
    {
      name: "Admin Dashboard",
      component: AdminDashboard,
      path: "/admin/dashboard",
      exact: false,
    },
    {
      name: "Admin Manajemen Soal",
      component: ManajemenSoal,
      path: "/admin/manajemen_soal",
      exact: false,
    },
    {
      name: "Admin Manajemen Soal - Detail",
      component: ManajemenSoalDetail,
      path: "/admin/detail/manajemen_soal/:id",
      exact: false,
    },
    {
      name: "Admin Manajemen Soal - Tambah Data",
      component: FormManajemenSoal,
      path: "/admin/tambah/manajemen_soal/:idPaket",
      exact: false,
    },
    {
      name: "Admin Manajemen Soal - Ubah Data",
      component: UpdateManajemenSoal,
      path: "/admin/ubah/manajemen_soal/:idPaket/:id",
      exact: false,
    },
    {
      name: "Admin Manajemen Materi",
      component: ManajemenMateri,
      path: "/admin/manajemen_materi",
      exact: false,
    },
    {
      name: "Admin Manajemen Materi",
      component: ManajemenMateriTambah,
      path: "/admin/tambah/manajemen_materi",
      exact: false,
    },

    {
      name: "Admin Manajemen Materi",
      component: ManajemenMateriUbah,
      path: "/admin/ubah/manajemen_materi/:id",
      exact: false,
    },

    {
      name: "Admin Manajemen Materi",
      component: ManajemenMateriDetail,
      path: "/admin/detail/manajemen_materi/:id",
      exact: false,
    },

    {
      name: "Admin Manajemen Pelatihan",
      component: ManajemenPelatihan,
      path: "/admin/manajemen_pelatihan",
      exact: false,
    },

    {
      name: "Admin Manajemen Pelatihan",
      component: CreateManajemenPelatihan,
      path: "/admin/tambah/manajemen_pelatihan",
      exact: false,
    },

    {
      name: "Admin Manajemen Pelatihan",
      component: UpdateManajemenPelatihan,
      path: "/admin/ubah/manajemen_pelatihan/:id",
      exact: false,
    },

    {
      name: "Admin Manajemen Pelatihan",
      component: KelolaKontenManajemenPelatihan,
      path: "/admin/kelola_konten/manajemen_pelatihan/:id",
      exact: false,
    },

    {
      name: "Admin Manajemen Pelatihan",
      component: DetailSoalManajemenPelatihan,
      path: "/admin/kelola_konten/detail_soal/manajemen_pelatihan/:id",
      exact: false,
    },

    {
      name: "Admin Manajemen Pelatihan",
      component: PenilaianManajemenPelatihan,
      path: "/admin/penilaian/manajemen_pelatihan/:id",
      exact: false,
    },

    {
      name: "Admin Manajemen Pelatihan",
      component: DetailDataPelatihanManajemenPelatihan,
      path: "/admin/detail/manajemen_pelatihan/data_pelatihan/:id",
      exact: false,
    },

    {
      name: "Admin Manajemen Pelatihan",
      component: DetailKontenPelatihanManajemenPelatihan,
      path: "/admin/detail/manajemen_pelatihan/konten_pelatihan/:id",
      exact: false,
    },

    {
      name: "Admin Manajemen Pelatihan",
      component: DetailPenilaianManajemenPelatihan,
      path: "/admin/detail/manajemen_pelatihan/penilaian/:id",
      exact: false,
    },

    {
      name: "Admin Manajemen Pelatihan",
      component: DetailDaftarPesertaManajemenPelatihan,
      path: "/admin/detail/manajemen_pelatihan/daftar_peserta/:id",
      exact: false,
    },

    {
      name: "Admin Manajemen User",
      component: ManajemenUser,
      path: "/admin/manajemen_user",
      exact: false,
    },

    {
      name: "Admin Manajemen Sertifikat",
      component: ManajemenSertifikat,
      path: "/admin/manajemen_sertifikat",
      exact: false,
    },

    {
      name: "Admin Manajemen Alumni Pelatihan Simdiklat",
      component: ManajemenAlumniSimdiklat,
      path: "/admin/manajemen_alumni_simdiklat",
      exact: false,
    },

    {
      name: "Admin Master Kategori Soal",
      component: MasterKategoriSoal,
      path: "/admin/master/kategori_soal",
      exact: false,
    },

    {
      name: "Admin Master Kategori Pengetahuan",
      component: MasterKategoriPengetahuan,
      path: "/admin/master/kategori_pengetahuan",
      exact: false,
    },

    {
      name: "Admin Master Jenis Pelatihan",
      component: MasterJenisPelatihan,
      path: "/admin/master/jenis_pelatihan",
      exact: false,
    },

    {
      name: "Admin Master Faq",
      component: MasterFaq,
      path: "/admin/master/faq",
      exact: false,
    },

    {
      name: "Admin Master Slider",
      component: MasterSlider,
      path: "/admin/master/slider",
      exact: false,
    },

    {
      name: "Admin Master Provinsi",
      component: MasterProvinsi,
      path: "/admin/master/provinsi",
      exact: false,
    },

    {
      name: "Admin Master Kabupaten",
      component: MasterKabupaten,
      path: "/admin/master/kabupaten",
      exact: false,
    },

    {
      name: "Admin Master Kecamatan",
      component: MasterKecamatan,
      path: "/admin/master/kecamatan",
      exact: false,
    },

    {
      name: "Admin Master Kelurahan",
      component: MasterKelurahan,
      path: "/admin/master/kelurahan",
      exact: false,
    },

    {
      name: "Admin Master Kategori Podcast",
      component: PodcastCategory,
      path: "/admin/master/kategori_podcast",
      exact: false,
    },

    {
      name: "Admin Master Kategori Kursus Video",
      component: VideoCourseCategory,
      path: "/admin/master/kategori_kursus_video",
      exact: false,
    },

    {
      name: "Admin Helpdesk Konsultasi",
      component: HelpdeskKonsultasi,
      path: "/admin/helpdesk/konsultasi",
      exact: false,
    },

    {
      name: "Admin Helpdesk Bantuan",
      component: HelpdeskBantuan,
      path: "/admin/helpdesk/bantuan",
      exact: false,
    },

    {
      name: "Admin Manajemen Slide Banner",
      component: AdminSlideBanner,
      path: "/admin/landing-page/slide-banner",
      exact: false,
    },

    {
      name: "Admin Manajemen Infografis",
      component: AdminInfografis,
      path: "/admin/landing-page/infografis",
      exact: false,
    },

    {
      name: "Admin Manajemen Event Pintar",
      component: AdminEventPintar,
      path: "/admin/landing-page/event-pintar",
      exact: false,
    },

    {
      name: "Admin Manajemen Identitas",
      component: ManajemenIdentitas,
      path: "/admin/landing-page/manajemen-identitas",
      exact: false,
    },

    {
      name: "Admin Manajemen Pengetahuan",
      component: ManajemenPengetahuan,
      path: "/admin/manajemen_pengetahuan",
      exact: true,
    },

    {
      name: "Admin Manajemen Pengetahuan Detail",
      component: ManajemenPengetahuanDetail,
      path: "/admin/manajemen_pengetahuan/:id",
      exact: true,
    },

    {
      name: "Admin Manajemen Pengetahuan Detail Pemateri",
      component: DetailKontenPemateri,
      path: "/admin/manajemen_pengetahuan/:id/konten_pemateri/:id_pemateri",
      exact: false,
    },

    {
      name: "Admin Manajemen Podcast",
      component: ManajemenPodcast,
      path: "/admin/manajemen_podcast",
      exact: false,
    },

    {
      name: "Admin Master Data TTE",
      component: MasterTTE,
      path: "/admin/master/tte",
      exact: false,
    },
    {
      name: "Admin Manajemen Kontributor Pengetahuan",
      component: ManajemenKontributorPengetahuan,
      path: "/admin/manajemen_kontributor_pengetahuan",
      exact: true,
    },
    {
      name: "Admin Verifikator Kontributor Pengetahuan",
      component: VerifikatorKontributorPengetahuan,
      path: "/admin/manajemen_kontributor_pengetahuan/verifikator/:id",
      exact: false,
    },
  ];

  const helpDeskSpecificRoutes = [
    {
      name: "Dashboard",
      component: AdminDashboard,
      path: "/",
      exact: true,
    },
    {
      name: "Admin Dashboard",
      component: AdminDashboard,
      path: "/admin/dashboard",
      exact: false,
    },
    {
      name: "Admin Manajemen Pelatihan",
      component: ManajemenPelatihan,
      path: "/admin/manajemen_pelatihan",
      exact: false,
    },
    {
      name: "Admin Manajemen User",
      component: ManajemenUser,
      path: "/admin/manajemen_user",
      exact: false,
    },
    {
      name: "Admin Helpdesk Konsultasi",
      component: HelpdeskKonsultasi,
      path: "/admin/helpdesk/konsultasi",
      exact: false,
    },

    {
      name: "Admin Helpdesk Bantuan",
      component: HelpdeskBantuan,
      path: "/admin/helpdesk/bantuan",
      exact: false,
    },
  ];

  const adminTTESpecificRoutes = [
    {
      name: "Dashboard",
      component: AdminDashboard,
      path: "/",
      exact: true,
    },
    {
      name: "Admin Dashboard",
      component: AdminDashboard,
      path: "/admin/dashboard",
      exact: false,
    },
    {
      name: "Admin Manajemen Pelatihan",
      component: ManajemenPelatihan,
      path: "/admin/manajemen_pelatihan",
      exact: false,
    },
    {
      name: "Admin Manajemen Pelatihan",
      component: DetailDataPelatihanManajemenPelatihan,
      path: "/admin/detail/manajemen_pelatihan/data_pelatihan/:id",
      exact: false,
    },
    {
      name: "Admin Master TTE",
      component: MasterTTE,
      path: "/admin/master/tte",
      exact: false,
    },
    {
      name: "Admin Manajemen Sertifikat",
      component: ManajemenSertifikat,
      path: "/admin/manajemen_sertifikat",
      exact: false,
    },
  ];

  return isAllPath
    ? [...commonRoutes, ...userSpecificRoutes, ...adminSpecificRoutes]
    : [
        ...commonRoutes,
        ...(level === "superadmin" || level === "admin"
          ? adminSpecificRoutes
          : level === "adminhelpdesk"
          ? helpDeskSpecificRoutes
          : level === "admintte"
          ? adminTTESpecificRoutes
          : userSpecificRoutes),
      ];
};

const IndexRoute: React.FC = () => {
  // use auth data
  const auth = useAuthUser();
  const dataAuth = auth() ?? null;

  const routes = generateRoutes(dataAuth, false);
  const allPath = generateRoutes(dataAuth, true).map((item) => item.path);

  const allUniquePaths = Array.from(new Set(allPath));

  const { keycloak, initialized } = useKeycloak();

  const signIn = useSignIn();

  const initAuth = async ({ token, expired_in }) => {
    try {
      const accessToken = await axios
        .post(
          `${env.REACT_APP_BE_PROD_BASE_URL}/keycloak/passport-token/callback`,
          null,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data);

      const user = await axios
        .get(`${env.REACT_APP_BE_PROD_BASE_URL}/auth/me`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => res.data);

      signIn({
        token: accessToken,
        expiresIn: expired_in,
        tokenType: "Bearer",
        authState: user,
      });

      // return Promise.resolve(user)
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    if (initialized) {
      if (keycloak.authenticated) {
        const token = keycloak.token;
        const expired_in = keycloak.idTokenParsed?.exp ?? 0;
        initAuth({ token, expired_in });
      }
    }
  }, [initialized]);

  if (!initialized) {
    return <></>;
  }

  if (!keycloak.authenticated && !initialized) {
    return <></>;
  }

  return (
    <BrowserRouter>
      <Switch>
        {routes.map((item, idx) => {
          return (
            <Route
              key={idx}
              exact={item.exact}
              path={item.path}
              component={item.component}
            />
          );
        })}
        {allUniquePaths.includes(window.location.pathname) && (
          <Route component={AccessNotFound} />
        )}
        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <KeycloakProvider client={authInstance}>
          <AuthProvider authType={"cookie"} authName={"_auth"}>
            <SsoSimpatikaController.Provider>
              <AuthWrapper>
                <IndexRoute />
              </AuthWrapper>
            </SsoSimpatikaController.Provider>
          </AuthProvider>
        </KeycloakProvider>
      </ChakraProvider>
    </BrowserRouter>
  );
};

export default Routes;
