import React, { useEffect } from "react";
import {
  Flex,
  Button,
  Text,
  Input,
  Grid,
  GridItem,
  Divider,
} from "@chakra-ui/react";
import parse from "html-react-parser";
import Select from "react-select";

import { useKelolaKontenManajemenPelatihanContext } from "../../../controller/admin/manajemen_pelatihan/kelola_konten";
import { useManajemenPengetahuanKontenPemateriContext } from "../../../controller/admin/manajemen_pengetahuan/konten-pemateri";

type Props = {
  setShowModalLatihan: Function;
  setShowModalDetailQuiz: Function;
  isReadOnly?: boolean;
};

/**
 * Renders the BoxDetailQuiz component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setShowModalLatihan - A function to set the visibility of the Latihan modal.
 * @param {Function} props.setShowModalDetailQuiz - A function to set the visibility of the DetailQuiz modal.
 * @return {JSX.Element} The rendered BoxDetailQuiz component.
 * @author Bagas Prasetyadi
 */

const BoxDetailQuiz = ({
  setShowModalLatihan,
  setShowModalDetailQuiz,
  isReadOnly = false,
}: Props) => {
  const { paketSoalId, quizDuration } =
    useManajemenPengetahuanKontenPemateriContext().state;
  const { setPengetahuanKontenPemateri: setState } =
    useManajemenPengetahuanKontenPemateriContext().disptach;

  const { optionsPaketSoal, paketSoal } =
    useKelolaKontenManajemenPelatihanContext().state;

  const { getPaketSoal } = useKelolaKontenManajemenPelatihanContext();

  const soalSelected = optionsPaketSoal.find(
    (val) => val.value === Number(paketSoalId)
  );

  const paketSoalSelected = paketSoal.find(
    (val) => val.id === Number(paketSoalId)
  );

  useEffect(() => {
    getPaketSoal();
  }, []);
  return (
    <>
      <Flex alignItems="center">
        <Text fontWeight="semibold" fontSize="md" mr={3}>
          Detail Quiz
        </Text>
      </Flex>
      <Divider my={4} />
      <Grid templateColumns="repeat(6, 1fr)" gap={4}>
        {/* Paket Soal */}
        <GridItem colSpan={1}>Paket Soal</GridItem>
        <GridItem colSpan={4}>
          <Select
            options={optionsPaketSoal}
            value={soalSelected}
            isDisabled={isReadOnly}
            onChange={(e) => setState("paketSoalId", e?.value)}
          />
        </GridItem>

        {/* Detail Soal */}
        <GridItem colSpan={1}>
          <Button
            colorScheme="green"
            w="full"
            onClick={() => setShowModalDetailQuiz(true)}
          >
            Detail Soal
          </Button>
        </GridItem>

        {/* Jumlah Soal */}
        <GridItem colSpan={1}>Jumlah Soal</GridItem>
        <GridItem colSpan={5}>
          <Text fontSize="sm">{paketSoalSelected?.soal?.length} Soal</Text>
        </GridItem>

        {/* Kategori Soal */}
        <GridItem colSpan={1}>Kategori Soal</GridItem>
        <GridItem colSpan={5}>
          <Text fontSize="sm">
            {paketSoalSelected?.kategori_paket_soal?.kategori_paket_soal}
          </Text>
        </GridItem>

        {/* Petunjuk Soal */}
        <GridItem colSpan={1}>Petunjuk Soal</GridItem>
        <GridItem colSpan={5}>
          <Text fontSize="sm">
            {parse(paketSoalSelected?.petunjuk_soal ?? "")}
          </Text>
        </GridItem>

        {/* Durai Latihan */}
        <GridItem colSpan={1}>Durasi Latihan</GridItem>
        <GridItem colSpan={5}>
          <Input
            fontSize="sm"
            value={quizDuration}
            readOnly={isReadOnly}
            onChange={(e) => setState("quizDuration", e.target.value)}
          />
        </GridItem>
      </Grid>
    </>
  );
};

export default BoxDetailQuiz;
