import { Box, Flex, Text, Button, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { usePengetahuanDetailContext } from "../../controller/pengetahuan/detail";
import { usePengetahuanAssesmentContext } from "../../controller/pengetahuan/assesment";
import ModalUploadAssesment from "../Admin/ModalPengetahuan/ModalUploadAssesment";
import { FaFileDownload } from "react-icons/fa";
import { parseISO, differenceInHours } from "date-fns";
import { MdUpload } from "react-icons/md";

type Props = {};

/**
 * Renders the AssesmentPengetahuan component.
 *
 * @param {Props} props - The component props.
 * @return {JSX.Element} The rendered component.
 * @author Bagas Prasetyadi
 */
const AssesmentPengetahuan = (props: Props) => {
  const { pengetahuanItem } = usePengetahuanDetailContext().state;
  const { isLoadingDownload } = usePengetahuanAssesmentContext().state;
  const { downloadAssesment, setPengetahuanAsessmentState } =
    usePengetahuanAssesmentContext().dispatch;

  const pengetahuanId = window.location.pathname.split("/", 4)[3];

  const startAssesmentSync =
    pengetahuanItem && parseISO(pengetahuanItem?.jadwal_selesai);
  const startAssesmentASync =
    pengetahuanItem &&
    (parseISO(pengetahuanItem?.tanggal_download_asesmen) as Date);
  const currentDate = new Date();

  const startAssesment =
    pengetahuanItem?.jenis_pengetahuan === "synchronized"
      ? startAssesmentSync
      : startAssesmentASync;

  let diffHours = 0;

  if (startAssesment instanceof Date) {
    diffHours = differenceInHours(currentDate, startAssesment);
  }

  //  hide upload file access after 5 days
  const hoursInFiveDays = 5 * 24;

  const shouldHideUploadFile =
    diffHours >= hoursInFiveDays ||
    pengetahuanItem?.status_pengetahuan === "selesai";

  const linkDriveAssesment =
    pengetahuanItem?.pengetahuan_materi_saya?.t_pengetahuan_asesmen?.assessment_files.find(
      (file) => file.link_drive !== null
    );

  return (
    <>
      {pengetahuanItem?.pengetahuan_materi_saya?.has_assessment ? (
        <Box rounded="lg" w="full" px={4} bg="white">
          <Flex>
            <Text fontSize="lg" fontWeight="semibold" mr={2}>
              Assignment
            </Text>
          </Flex>
          <Text mt={2} fontSize="sm">
            Download assignment di bawah ini dan upload file assignment yang
            sudah dikerjakan.
          </Text>
          <Stack mt={2} spacing={2} direction="row">
            <Button
              colorScheme="orange"
              onClick={() =>
                downloadAssesment(
                  pengetahuanId,
                  pengetahuanItem?.assessment?.judul,
                  pengetahuanItem?.tanggal_download_asesmen,
                  pengetahuanItem?.jenis_pengetahuan
                )
              }
              isLoading={isLoadingDownload}
              rightIcon={<FaFileDownload />}
            >
              Download Assignment
            </Button>
            {linkDriveAssesment && !shouldHideUploadFile && (
              <Button
                colorScheme="blue"
                rightIcon={<MdUpload />}
                onClick={() =>
                  window.open(linkDriveAssesment.link_drive ?? "#", "_blank")
                }
              >
                Upload Assignment
              </Button>
            )}
          </Stack>
        </Box>
      ) : (
        <Text
          fontSize="md"
          fontWeight="semibold"
          mt={2}
          textAlign="center"
          w="full"
        >
          Tidak ada assignment pada pengetahuan ini
        </Text>
      )}
      <ModalUploadAssesment />
    </>
  );
};

export default AssesmentPengetahuan;
